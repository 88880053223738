import { get, post } from './http'

export const chargeIndex = () => {
  const url = '/charge'
  return get(url)
}

export const submitOrder = (data) => {
  const url = '/charge/order_submit'
  return post(url, data)
}

export const chargeCard = (data) => {
  const url = '/charge/card'
  return post(url, data)
}

export const chargeLog = (params) => {
  const url = '/charge/log'
  return get(url, params)
}
